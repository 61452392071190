import humanResourcesRouter from './task-management/human-resources'
import taskFinanceRouter from './task-management/task-finance'
import taskInquiryRouter from './task-management/task-inquiry'
import productRouter from './task-management/product'

let taskManagementRouter = [
  // 3.0看板首页
  {
    path: '/task-management',
    name: 'TaskManagement',
    meta: {
      index: 1,
    },
    component: () => import( /* webpackChunkName: "about" */ '../views/task-management/index.vue'),
  },
  // 看板-市场详情
  {
    path: '/task-management/board/market-list',
    name: 'TaskBoardMarketList',
    meta: {
      index: 2,
    },
    component: () => import( /* webpackChunkName: "about" */ '../views/task-management/board/board-output/market-list.vue'),
  },
  // 看板-部门详情（多个部门）
  {
    path: '/task-management/board/department-list',
    name: 'TaskBoardDepartmentList',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */ '../views/task-management/board/board-output/department-list.vue'),
  },
  // 看板-部门详情（单个部门）
  {
    path: '/task-management/board/department-alone-list',
    name: 'TaskBoardDepartmentAloneList',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */ '../views/task-management/board/board-output/department-alone-list.vue'),
  },
  // 看板-人员详情
  {
    path: '/task-management/board/employee-list',
    name: 'TaskBoardEmployeelList',
    meta: {
      index: 4,
    },
    component: () => import( /* webpackChunkName: "about" */ '../views/task-management/board/board-output/employee-list.vue'),
  },
  // 看板-个人详情
  {
    path: '/task-management/board/employee-single',
    name: 'TaskBoardEmployeeSingle',
    meta: {
      index: 5,
    },
    component: () => import( /* webpackChunkName: "about" */ '../views/task-management/board/board-output/employee-single.vue'),
  },

  // 任务中心
  {
    path: '/task-management/setup/task-center',
    name: 'TaskManagementSetUpTaskCenter',
    meta: {
      index: 2,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-center.vue'),
  },
  // ----------------------任务设置--------------------------
  // 任务设置--总裁 
  {
    path: '/task-management/setup/task-set-president',
    name: 'TaskManagementSetUpTaskSetPresident',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-set-president.vue'),
  },
  // 任务设置--总经理
  {
    path: '/task-management/setup/task-set-executive',
    name: 'TaskManagementSetUpTaskSetExecutive',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-set-executive.vue'),
  },
  // 任务设置--前台总监
  {
    path: '/task-management/setup/task-set-foreground-director',
    name: 'TaskManagementSetUpTaskSetForegroundDirector',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-set-foreground-director.vue'),
  },
  // 任务设置--督导经理
  {
    path: '/task-management/setup/task-set-supervision-manager',
    name: 'TaskManagementSetUpTaskSetSupervisionManager',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-set-supervision-manager.vue'),
  },
  // 任务设置--转单经理
  {
    path: '/task-management/setup/task-set-transfer-manager',
    name: 'TaskManagementSetUpTaskSetTransferManager',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-set-transfer-manager.vue'),
  },
  // 任务设置--签单经理 
  {
    path: '/task-management/setup/task-set-signing-manager',
    name: 'TaskManagementSetUpTaskSetSigningManager',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-set-signing-manager.vue'),
  },
  // 任务设置--话务经理
  {
    path: '/task-management/setup/task-set-traffic-manager',
    name: 'TaskManagementSetUpTaskSetTrafficManager',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-set-traffic-manager.vue'),
  },

  // ----------------------任务详情--------------------------
  // 任务修改--前台总监
  {
    path: '/task-management/setup/task-modify-foreground-director',
    name: 'TaskManagementSetUpTaskModifyForegroundDirector',
    meta: {
      index: 5,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-modify-foreground-director.vue'),
  },
  // 任务分配--前台总监
  {
    path: '/task-management/setup/task-allocation-foreground-director',
    name: 'TaskManagementSetUpTaskAllocationForegroundDirector',
    meta: {
      index: 5,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-allocation-foreground-director.vue'),
  },
  // 任务详情--前台总监
  {
    path: '/task-management/setup/task-details-foreground-director',
    name: 'TaskManagementSetUpTaskDetailsForegroundDirector',
    meta: {
      index: 4,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-details-foreground-director.vue'),
  },
  // 任务详情--（前台总监以外的其他人员）
  {
    path: '/task-management/setup/task-details',
    name: 'TaskManagementSetUpTaskDetails',
    meta: {
      index: 4,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-details.vue'),
  },

  // 审批页面
  {
    path: '/task-management/setup/task-approve',
    name: 'TaskManagementSetUpTaskApprove',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-approve.vue'),
  },
  // 审批详情页面
  {
    path: '/task-management/setup/task-approve-detail',
    name: 'TaskManagementSetUpTaskApproveDetail',
    meta: {
      index: 4,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-approve-detail.vue'),
  },
  // 调整记录
  {
    path: '/task-management/setup/task-adjust',
    name: 'TaskManagementSetUpTaskAdjust',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */
      '../views/task-management/setup/task-adjust.vue'),
  },

  // 1.8.53 我的模块
  {
    path: '/task-management/my/info',
    name: 'TaskManagementMyIndo',
    meta: {
      index: 2,
    },
    component: () => import( /* webpackChunkName: "about" */ '../views/task-management/my/info.vue'),
  },

  // 人事
  ...humanResourcesRouter,
  // 财务
  ...taskFinanceRouter,
  // 预审
  ...taskInquiryRouter,
  // 产品
  ...productRouter,

  // 3.0优化
  // 看板-个人详情-客服部 自然到店客户详情
  {
    path: '/task-management/board/natural-in-store-customer-list',
    name: 'TaskBoardNaturalInStoreCustomerList',
    meta: {
      index: 4,
    },
    component: () => import( /* webpackChunkName: "about" */ '../views/task-management/board/board-output/natural-in-store-customer-list.vue'),
  },

]
export default taskManagementRouter;