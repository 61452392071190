let router = [
    //模块
    {
        path: '/task-management/board/product/market-list',
        name: 'TaskBoardProductMarketList',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/product/market-list.vue'),
        meta: {
            index: 2,
        },
    },
    {
        path: '/task-management/board/product/employee-list',
        name: 'TaskBoardProductEmployeeList',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/product/employee-list.vue'),
        meta: {
            index: 3,
        },
    },
    {
        path: '/task-management/board/product/employee-single',
        name: 'TaskBoardProductEmployeeSingle',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/product/employee-single.vue'),
        meta: {
            index: 5,
        },
    },
    {
        path: '/task-management/board/product/upload-task',
        name: 'TaskBoardProductUploadTask',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/product/upload-task.vue'),
        meta: {
            index: 6,
        },
    },
    {
        path: '/task-management/board/product/examine-detail',
        name: 'TaskBoardProductExamineDetail',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/product/examine-detail.vue'),
        meta: {
            index: 6,
        },
    },

    // -------------------------任务设置-------------------------------
    {
        path: '/task-management/setup/product/index',
        name: 'TaskSetupProduct',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/product/index.vue'),
        meta: {
            index: 2,
        },
    },
    {
        path: '/task-management/setup/product/examine-list',
        name: 'TaskSetupProductExamineList',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/product/examine-list.vue'),
        meta: {
            index: 3,
        },
    },
    {
        path: '/task-management/setup/product/task-details',
        name: 'TaskSetupProductTaskDetails',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/product/task-details.vue'),
        meta: {
            index: 4,
        },
    },
    {
        path: '/task-management/setup/product/sub-task',
        name: 'TaskSetupProductSubTask',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/product/sub-task.vue'),
        meta: {
            index: 5,
        },
    },
    {
        path: '/task-management/setup/product/create-sub-task',
        name: 'TaskSetupProductCreateSubTask',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/product/create-sub-task.vue'),
        meta: {
            index: 6,
        },
    },
    {
        path: '/task-management/setup/product/sub-task-details',
        name: 'TaskSetupProductSubTaskDetails',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/product/sub-task-details.vue'),
        meta: {
            index: 6,
        },
    },
]
export default router;