/* 财务看板 */
let taskFinanceRouter = [
  // 看板-市场列表
  {
    path: '/task-management/board/finance/market-list',
    name: 'TaskBoardFinanceMarketList',
    meta: {
      index: 2,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/finance/market-list.vue'),
  },
  // 市场综述--经理以下-任务结果上传记录
  {
    path: '/task-management/board/finance/market-overview',
    name: 'TaskBoardFinanceMarketOverView',
    meta: {
      index: 2,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/finance/market-overview.vue'),
  },
  // 任务结果详情
  {
    path: '/task-management/board/finance/task-upload-list',
    name: 'TaskBoardFinanceTaskUploadList',
    meta: {
      index: 5,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/finance/task-upload-list.vue'),
  },
  // 任务结果上传
  {
    path: '/task-management/board/finance/task-upload',
    name: 'TaskBoardFinanceTaskUpload',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/finance/task-upload.vue'),
  },
  // 人员列表
  {
    path: '/task-management/board/finance/employee-list',
    name: 'TaskBoardFinanceEmployeeList',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/finance/employee-list.vue'),
  },
  // 人员详情
  {
    path: '/task-management/board/finance/employee-single',
    name: 'TaskBoardFinanceEmployeeSingle',
    meta: {
      index: 4,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/finance/employee-single.vue'),
  },
  /* ===============================任务管理中心=================================================== */
  // 任务首页
  {
    path: '/task-management/setup/finance/index',
    name: 'TaskSetupFinanceIndex',
    meta: {
      index: 2,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/finance/index.vue'),
  },
  // 综述详情
  {
    path: '/task-management/setup/finance/details/overview-details',
    name: 'TaskSetupFinanceOverviewDetails',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/finance/details/overview-details.vue'),
  },
  // 审批列表
  {
    path: '/task-management/setup/finance/details/examine-list',
    name: 'TaskSetupFinanceExamineList',
    meta: {
      index: 4,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/finance/details/examine-list.vue'),
  },
]
export default taskFinanceRouter;