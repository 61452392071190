<template>
    <!-- 头部导航 -->
    <div class="header-style">
        <div class="header-back">
            <span @click="leftClick">
                <van-icon v-if="leftIcon || leftNav" class="header-back-icon" name="arrow-left" />{{ leftNav }}
            </span>
        </div>
        <div class="header-title">{{ title }}</div>
        <div class="header-add">
            <span @click="rightClick" v-if="showRightContent">
                <img v-if="rightIcon" :src="require(`@/assets/${rightIcon}.png`)" :class="'header-right-icon '+ rightIconClass" alt="">
                <img v-if="rightIconSvg" :src="require(`@/assets/${rightIconSvg}.svg`)" :class="'header-right-icon ' + rightIconClass" alt="">
                <slot>
                  <span v-if="!hintData">{{ rightNav }}</span>
                    <van-badge :content="hintData" :max="99" v-else>
                      <span class="badge-span">{{ rightNav }}</span>
                    </van-badge>
                </slot>
            </span>
            <slot name="rightContent"></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: "headerNav",
  data() {
    return {};
  },
  props: {
    //左侧文字
    leftNav: {
        type: String,
        default: "",
    },
    //是否展示左侧图标
    leftIcon: {
        type: Boolean,
        default: false,
    },
    //右侧文字
    rightNav: {
        type: String,
        default: "",
    },
    //右侧图标
    rightIcon: {
        type: String,
        default: undefined,
    },
    //右侧svg图标
    rightIconSvg:{
        type: String,
        default: undefined,
    },
    rightIconClass:{
        type: String,
        default: undefined,
    },
    //中间文字
    title: {
      type: String,
      default: "",
    },
    // 右侧气泡
    hintData: {
      type: Number,
      default: 0,
    },
    //右侧内容是否展示
    showRightContent: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    //左侧点击事件
    leftClick() {
      this.$emit("leftClick");
    },
    //右侧点击事件
    rightClick() {
      this.$emit("rightClick");
    },
  },
  mounted() {
    this.info = this.$route.query;
    // console.log(this.rightClick)
  },
};
</script>

<style scoped lang="scss">
.header-style ::v-deep {
  height: 49px;
  background: #3975c6;
  display: flex;
  position: relative;
  align-items: center;
  z-index: 9999999;
  div {
    width: 33.33%;
  }

  .header-title {
    line-height: 49px;
    font-size: 18.5px;
    // font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ffffff;
    width: 100%;
    text-align: center;
  }

  .header-add {
    // text-align: right;
    padding-right: 10px;
    position: absolute;
    right: 0;
    height: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    span {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        // margin-right: 3px;
        margin-top: 2px;
      }
    }
    .badge-span {
      display: block !important;
    }
    .van-badge {
      min-width: 18px;
      font-size: 12px;
      background: #ff6157;
      text-align: center;
      padding: 2px 2.6px 1px 0px;
      border: 0;
    }

    .van-badge--fixed {
      top: -1px;
      right: 5px;
    }
  }

  .header-back {
    text-align: left;
    padding-left: 10px;
    position: absolute;
    left: 0;
    height: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;

    .header-back-icon {
      // font-size: 40px;
      margin-right: 12px;
    .header-add {
        text-align: right;
        padding-right: 17px;
        position: absolute;
        right: 0;
        // height: 15px;
        font-size: 15px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 16px;
        span{
            display: flex;
            // align-items: center;
            justify-content: flex-end;
        }
    }
  }
}

  .header-right-icon {
    width: 16px;
    height: 16px;
  }
}
</style>
