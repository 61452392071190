let dataBoardRouter = [
    //模块
    {
        path: '/dataBoard',
        name: 'dataBoard',
        component: () => import( /* webpackChunkName: "about" */ '../views/dataBoard/index.vue'),
        meta: {
            keepAlive: true,
        },
    },
    {
        path: '/dataBoard/indicators1',
        name: 'indicators1',
        component: () => import( /* webpackChunkName: "about" */ '../views/dataBoard/indicators1.vue'),
    },
    {
        path: '/dataBoard/indicators2',
        name: 'indicators2',
        component: () => import( /* webpackChunkName: "about" */ '../views/dataBoard/indicators2.vue'),
    },
    {
        path: '/dataBoard/message',
        name: 'dataBoardMessage',
        component: () => import( /* webpackChunkName: "about" */ '../views/dataBoard/dataBoardMessage.vue'),
    },
    {
        path: '/dataBoard/rankDetails',
        name: 'rankDetails',
        component: () => import( /* webpackChunkName: "about" */ '../views/dataBoard/rankDetails.vue'),
    },

]
export default dataBoardRouter;