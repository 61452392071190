
let TdirectorRouter = [
    //话务总监看板
    {
        path: '/Tdirector',
        name: 'TdirectorName',
        component: () => import( /* webpackChunkName: "about" */ '../views/Tdirector/index.vue'),
        meta: { keepAlive: true }
    },
    //话务进线排行榜详情
    {
        path: '/TdirectorDetails',
        name: 'TdirectorDetailsName',
        component: () => import( /* webpackChunkName: "about" */ '../views/Tdirector/details.vue')
    },
]
export default TdirectorRouter;