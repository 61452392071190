<template>
  <div id="app1" :class="phoneModel">
    <van-loading v-if="!isErro" vertical size="24px" class="loading-style2">加载中，请稍后...</van-loading>
    <keep-alive>
      <transition :name="transitionName" v-if="$route.meta.keepAlive" @before-enter="beforeEnter">
        <router-view />
      </transition>
    </keep-alive>
    <transition :name="transitionName" v-if="!$route.meta.keepAlive" @before-enter="beforeEnter">
      <router-view />
    </transition>
  </div>
</template>
<script>
import api from '@/api/api.js'
import { judgeClient } from '@/utils/judgeClient.js'

export default {
  data () {
    return {
      gap_time: 0,
      beforeUnload_time: 0,
      transitionName: "",
      phoneModel: "",
      isErro: false,
    };
  },
  methods: {
    beforeunloadHandler () {
      this.beforeUnload_time = new Date().getTime();
    },
    async unloadHandler () {
      this.gap_time = new Date().getTime() - this.beforeUnload_time;
      //判断是窗口关闭还是刷新 毫秒数判断 网上大部分写的是5
      if (this.gap_time <= 5) {
        await api.logOut() // 退出登录接口 这里应当换为个人的登出方法
      } else {

      }
    },
    beforeEnter() {
      this.isErro = true;
    }

  },
  watch: {
    $route (to, from) {
      if (this.isIosMoveBack) {
        this.transitionName = ''
        this.$store.commit('setIsIosMoveBack', false)
      } else {
        if (to.meta.index > from.meta.index) {
          this.transitionName = 'slide-left'
        } else if (to.meta.index < from.meta.index) {
          this.transitionName = 'slide-right'
        } else {
          this.transitionName = ''
        }
      }
    }
  },

  computed: {
    // 返回是否过度取消机制状态
    isIosMoveBack () {
      return this.$store.state.isIosMoveBack
    }
  },
  created () {
    var userAgent = navigator.userAgent; // 获取User Agent信息
    if (/(iPhone|iPad|iPod)/i.test(userAgent)) {
      this.phoneModel = 'ios'
    } else if (/android/i.test(userAgent)) {
      this.phoneModel = 'android'
    }
  },
  destroyed () {
    // 移除监听
    window.removeEventListener("beforeunload", () => this.beforeunloadHandler());
    window.removeEventListener("unload", () => this.unloadHandler());
    document.body.removeEventListener('touchmove', () => { this.$store.commit('setIsIosMoveBack', false) }, false)
  },
  mounted () {
    // 监听浏览器关闭
    window.addEventListener("beforeunload", () => this.beforeunloadHandler());
    window.addEventListener("unload", () => this.unloadHandler());
    if (judgeClient() == 'iOS') {
      document.body.addEventListener('touchmove', () => {
        this.$store.commit('setIsIosMoveBack', true)
      }, false)
    }
  },
  // 在当前路由改变，但是该组件被复用时调用
  beforeRouteUpdate (to, from, next) {
    // 路由过渡动画的监听判断
    if (this.isIosMoveBack) {
      // 清除过渡效果
      this.transitionName = ''
      // 顺便重置监听状态 
      this.$store.commit('setIsIosMoveBack', false)
    } else {
      // 加上过渡效果
      this.transitionName = 'slide-right'
    }
  }
}
</script>
<style lang="scss">
#app1 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: Noto Sans SC;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.loading-style2{
  width: 100%;
  position: absolute !important;
  top: calc(50% - 21px);
}

.vant-list-style {
  .van-list__finished-text {
    font-size: 11px;
    // font-family: Source Han Sans SC;
    font-weight: 400;
    color: #AAAAAA;
    // height: 40px;
    // line-height: 20px;
  }

  .van-loading__text {
    font-size: 11px;
    // font-family: Source Han Sans SC;
    font-weight: 400;
    color: #AAAAAA;
    // display: none;
  }
}

/* 页面切换动画 */
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  /* will-change属性可以提前通知浏览器我们要对元素做什么动画，这样浏览器可以提前准备合适的优化设置 */
  will-change: transform;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  position: absolute;
  width: 100%;
  left: 0;
}

.slide-right-enter {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.slide-right-leave-active {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.slide-left-enter {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.slide-left-leave-active {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.android .item-content>p {
  align-items: center;

  .old::before {
    top: calc(50% - 4px)!important;
  }
}
</style>
