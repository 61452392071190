let engineeringRouter = [
    //工程模块
    {
        path: '/engineering',
        name: 'engineeringName',
        component: () => import( /* webpackChunkName: "about" */ '../views/engineering/index.vue'),
        redirect: '/engineering/construction',
        children: [
            //在建工程首页
            {
                path: 'construction',
                name: 'engineeringConstructionName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/index.vue'),
            },
            // //客户信息
            {
                path: 'construction/clicntDetail',
                name: 'engineeringClicntDetailName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/clientDetails.vue'),
            },
            //巡检整改
            {
                path: 'construction/patrol',
                name: 'engineeringPatrollName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/patrol'),
                children: [
                    //巡检记录
                    {
                        path: 'patrolList',
                        name: 'engineeringPatrolListlName',
                        component: () => import( /* webpackChunkName: "about" */
                            '../views/engineering/construction/patrol/patrolList'),
                    },
                    //现存隐患
                    {
                        path: 'extantDanger',
                        name: 'engineeringExtantDangerName',
                        component: () => import( /* webpackChunkName: "about" */
                            '../views/engineering/construction/patrol/extantDanger'),
                    },
                    //解决隐患
                    {
                        path: 'solveDanger',
                        name: 'engineeringSolveDangerName',
                        component: () => import( /* webpackChunkName: "about" */
                            '../views/engineering/construction/patrol/solveDanger'),
                    },
                    //符合标准
                    {
                        path: 'standard',
                        name: 'engineeringStandardName',
                        component: () => import( /* webpackChunkName: "about" */
                            '../views/engineering/construction/patrol/standard'),
                    }
                ]
            },
            //辅材申领
            {
                path: 'construction/materials',
                name: 'engineeringMaterialsName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/materials/index'),
            },
            //申领单据详情详情
            {
                path: 'construction/materialsDetails',
                name: 'engineeringMaterialsDetailsName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/materials/details'),
            },
            //辅材申领
            {
                path: 'construction/materialsApply',
                name: 'engineeringMaterialsApplyName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/materials/materialsApply'),
            },
            //辅材修改
            {
                path: 'construction/materialsApplyEdit',
                name: 'engineeringMaterialsApplyEditName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/materials/materialsApplyEdit'),
            },
            //巡检详情
            {
                path: 'construction/patrol/patrolDetails',
                name: 'engineeringPatrolDetailslName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/patrol/patrolList/patrolDetails'),
            },
            //整改
            {
                path: 'construction/patrol/amendUp',
                name: 'engineeringPatrolAmendUpName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/patrol/extantDanger/amendUp'),
            },
            //整改记录
            {
                path: 'construction/patrol/amendRecord',
                name: 'engineeringPatrolAmendRecordName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/patrol/amendRecord'),
            },
            //工程进度
            {
                path: 'construction/projectPlan',
                name: 'engineeringProjectPlanName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/projectPlan/index.vue'),
            },
            //工程进度 查看工程进度详情
            {
                path: 'construction/projectPlan/check',
                name: 'engineeringCheckProjectPlanName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/projectPlan/check.vue'),
            },
            //工程进度 查看工程进度 操作记录
            {
                path: 'construction/projectPlan/check/record',
                name: 'engineeringRecordProjectPlanName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/projectPlan/record.vue'),
            },
            //工程进度 查看工程进度完成上传
            {
                path: 'construction/projectPlan/finishUp',
                name: 'engineeringFinishUpProjectPlanName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/projectPlan/finishUp.vue'),
            },
            //分包单 分包单详情 上传图片和备注
            {
                path: 'construction/subpackage/handle',
                name: 'engineeringSubpackageHandle',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/subpackage/handle.vue'),
            },
            //分包单 分包单详情 查看图片备注审核
            {
                path: 'construction/subpackage/detailView',
                name: 'engineeringSubpackageDetailView',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/subpackage/detailView.vue'),
            },
            //分包单 分包单详情 查看项目
            {
                path: 'construction/subpackage/detail',
                name: 'engineeringSubpackageDetail',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/subpackage/detail.vue'),
            },
            //分包单 详情列表
            {
                path: 'construction/subpackage/detailList',
                name: 'engineeringSubpackageDetailList',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/subpackage/detailList.vue'),
            },
            // 分包单待上传页面
            {
                path: 'construction/subpackage/ToBeUploaded',
                name: 'ToBeUploaded',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/engineering/construction/subpackage/ToBeUploaded.vue'),
            },
        ]
    },
]
export default engineeringRouter;