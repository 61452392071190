let presidentRouter = [
    //模块
    {
        path: '/president',
        name: 'president',
        component: () => import( /* webpackChunkName: "about" */ '../views/president/index.vue'),
        meta: {
            keepAlive: true,
        },
    },
    {
        path: '/president/monthDetails',
        name: 'monthDetails',
        component: () => import( /* webpackChunkName: "about" */ '../views/president/monthDetails.vue'),
    },
    {
        path: '/president/channelDetails',
        name: 'channelDetails',
        component: () => import( /* webpackChunkName: "about" */ '../views/president/channelDetails.vue'),
    },

]
export default presidentRouter;