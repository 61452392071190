import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import './components.scss';
import 'lib-flexible'
import echarts from 'echarts'
import { hasPermission,hasRolePermission } from './hooks/usePermission'//权限判断
import headerNav from './components/header.vue'//头部导航
import hintModel from './components/hintModel.vue'//提交提示
import pullRefresh from './components/pullRefresh.vue'//下拉刷新
import VueTouch from 'vue-touch'
import VueAnimateNumber from 'vue-animate-number' // 数字滚动
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/text/text.css";
import "./utils/ceiling";
Vue.use(VueTouch, {name: 'v-touch'});
Vue.use(Vant);
Vue.use(hasPermission);
Vue.use(hasRolePermission);
Vue.component('headerNav', headerNav)   
Vue.component('hintModel', hintModel)
Vue.component('pullRefresh', pullRefresh)
Vue.use(Element, { size: 'small', zIndex: 3000 });
Vue.config.productionTip = false
Vue.use(VueAnimateNumber)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
