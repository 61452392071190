import Vue from 'vue'
//验证权限
export function hasPermission () {
    Vue.prototype.hasPermission = function (value) {
        if (!sessionStorage.getItem('USERINFO')) return
        //获取按钮权限
        let RoleBtnList = JSON.parse(sessionStorage.getItem('USERINFO')).btn || []
        return RoleBtnList.includes(value);
    }
}

// 任务看板 不同角色的权限
export function hasRolePermission () {
    Vue.prototype.hasRolePermission = function (value) {
        if (!localStorage.getItem("USER_ROLE")) return
        //获取按钮权限
        let RoleBtnList = JSON.parse(localStorage.getItem("USER_ROLE")).btn || []
        return RoleBtnList.includes(value);
    }
}
