let deepenRouter = [
    //深化模块
    {
        path: '/deepen',
        name: 'deepenName',
        component: () => import( /* webpackChunkName: "about" */ '../views/deepen/index.vue'),
        redirect: '/deepen/construction',
        children: [
            //在建工程首页
            {
                path: 'construction',
                name: 'deepenConstructionName',
                meta: {
                    keepAlive: true,
                },
                component: () => import( /* webpackChunkName: "about" */
                    '../views/deepen/construction/index.vue'),
            },
            //客户信息
            {
                path: 'construction/clicntDetail',
                name: 'deepenClicntDetailName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/deepen/construction/clientDetails.vue'),
            },
            // 销售单详情
            {
                path: 'construction/SelfDetails/:id',
                name: 'deepenSelfDetailsName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/deepen/construction/SelfDetails.vue'),
            },
            //巡检整改
            {
                path: 'construction/patrol',
                name: 'deepenPatrollName',
                component: () => import( /* webpackChunkName: "about" */ '../views/deepen/construction/patrol'),
                children: [
                    //巡检记录
                    {
                        path: 'patrolList',
                        name: 'deepenPatrolListlName',
                        component: () => import( /* webpackChunkName: "about" */
                            '../views/deepen/construction/patrol/patrolList'),
                    },
                    //现存隐患
                    {
                        path: 'extantDanger',
                        name: 'deepenExtantDangerName',
                        component: () => import( /* webpackChunkName: "about" */
                            '../views/deepen/construction/patrol/extantDanger'),
                    },
                    //解决隐患
                    {
                        path: 'solveDanger',
                        name: 'deepenSolveDangerName',
                        component: () => import( /* webpackChunkName: "about" */
                            '../views/deepen/construction/patrol/solveDanger'),
                    },
                    //符合标准
                    {
                        path: 'standard',
                        name: 'deepenStandardName',
                        component: () => import( /* webpackChunkName: "about" */
                            '../views/deepen/construction/patrol/standard'),
                    }
                ]
            },
            //新增巡检记录
            {
                path: 'construction/patrol/addPatrol',
                name: 'deepenAddPatroName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/deepen/construction/patrol/patrolList/add'),
                children: []
            },
            //巡检详情
            {
                path: 'construction/patrol/patrolDetails',
                name: 'deepenPatrolDetailslName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/deepen/construction/patrol/patrolList/patrolDetails'),
            },
            //巡检审核拒绝
            {
                path: 'construction/patrol/auditRefulse',
                name: 'deepenPatrolAuditRefulselName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/deepen/construction/patrol/extantDanger/auditRefulse'),
            },
            //整改记录
            {
                path: 'construction/patrol/amendRecord',
                name: 'deepenPatrolAmendRecordName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/deepen/construction/patrol/amendRecord'),
            },
            //工程进度
            {
                path: 'construction/projectPlan',
                name: 'deepenProjectPlanName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/deepen/construction/projectPlan/index.vue'),
            },
            //工程进度 查看工程进度详情
            {
                path: 'construction/projectPlan/check',
                name: 'deepenCheckProjectPlanName',
                component: () => import( /* webpackChunkName: "about" */
                '../views/deepen/construction/projectPlan/check.vue'),
            },
            //工程进度 查看工程进度 操作记录
            {
                path: 'construction/projectPlan/check/record',
                name: 'deepenCheckRecordProjectPlanName',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/deepen/construction/projectPlan/record.vue'),
            },
            //工程进度 查看工程进度完成上传
            {
                path: 'construction/projectPlan/finishUp',
                name: 'deepenFinishUpProjectPlanName',
                component: () => import( /* webpackChunkName: "about" */
                '../views/deepen/construction/projectPlan/finishUp.vue'),
            },
            //量房信息
            {
                path: 'construction/roomInfo',
                name: 'deepenUserInfoRoomInfo',
                component: () => import('../views/deepen/construction/userInfo/roomInfo.vue'),
            },
            //设计图纸
            {
                path: 'construction/designDrawing',
                name: 'deepenUserInfoDesignDrawing',
                component: () => import('../views/deepen/construction/userInfo/designDrawing.vue'),
            },
            //预算单
            {
                path: 'construction/budgetSheet',
                name: 'deepenUserInfoBudgetSheet',
                component: () => import('../views/deepen/construction/userInfo/budgetSheet.vue'),
            },
            //选材单
            {
                path: 'construction/materialSheet',
                name: 'deepenUserInfoMaterialSheet',
                component: () => import('../views/deepen/construction/userInfo/materialSheet.vue'),
            },
            //文档管理
            {
                path: 'construction/fileManage',
                name: 'deepenUserInfoFileManage',
                component: () => import('../views/deepen/construction/userInfo/fileManage.vue'),
            },
            //合同管理
            {
                path: 'construction/contractManage',
                name: 'deepenUserInfoContractManage',
                component: () => import('../views/deepen/construction/userInfo/contractManage.vue'),
            },
            //合同管理 详情
            {
                path: 'construction/contractManageDetail',
                name: 'deepenUserInfoContractManageDetail',
                component: () => import('../views/deepen/construction/userInfo/contractManageDetail.vue'),
            },
        ]
    },
]
export default deepenRouter;