/* 预审看板 */
let taskInquiryRouter = [
  // 看板-市场列表
  {
    path: '/task-management/board/inquiry/market-list',
    name: 'TaskBoardInquiryMarketList',
    meta: {
      index: 2,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/inquiry/market-list.vue'),
  },
  // 人员列表
  {
    path: '/task-management/board/inquiry/employee-list',
    name: 'TaskBoardInquiryEmployeeList',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/inquiry/employee-list.vue'),
  },
  // 人员详情
  {
    path: '/task-management/board/inquiry/employee-single',
    name: 'TaskBoardInquiryEmployeeSingle',
    meta: {
      index: 4,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/inquiry/employee-single.vue'),
  },
  // 任务设置或者市场综述
  {
    path: '/task-management/board/inquiry/employee-details',
    name: 'TaskBoardInquiryEmployeeDetails',
    meta: {
      index: 4,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/inquiry/employee-details.vue'),
  },
   // 任务成果上传
   {
    path: '/task-management/board/inquiry/upload-task',
    name: 'TaskBoardInquiryUpLoadTask',
    meta: {
      index: 5,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/inquiry/upload-task.vue'),
  },
  // ===============任务管理中心=================
  {
    path: '/task-management/setup/inquiry/index',
    name: 'TaskSetupInquiryIndex',
    meta: {
      index: 2,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/inquiry/index.vue'),
  },
  // 下发工地罚金任务
  {
    path: '/task-management/setup/inquiry/details/add-sitePenalty',
    name: 'TaskSetupInquiryDetailsSitePenalty', 
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/inquiry/details/add-sitePenalty.vue'),
  },
  // 任务设置/详情
  {
    path: '/task-management/setup/inquiry/details/task-designing',
    name: 'TaskSetupInquiryTaskDesigning',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/inquiry/details/task-designing.vue'),
  },
  // 审批列表
  {
    path: '/task-management/setup/inquiry/details/examine-list',
    name: 'TaskSetupInquiryExamineList',
    meta: {
      index: 3,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/inquiry/details/examine-list.vue'),
  },
  // 审批详情
  {
    path: '/task-management/setup/inquiry/details/examine-details',
    name: 'TaskSetupInquiryExamineDetails',
    meta: {
      index: 5,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/inquiry/details/examine-details.vue'),
  },
  // 子任务列表
  {
    path: '/task-management/setup/inquiry/details/subtask-list',
    name: 'TaskSetupInquirySubtaskList',
    meta: {
      index: 4,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/inquiry/details/subtask-list.vue'),
  },
  // 添加子任务
  {
    path: '/task-management/setup/inquiry/details/add-subtask',
    name: 'TaskSetupInquiryAddSubtask',
    meta: {
      index: 5,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/inquiry/details/add-subtask.vue'),
  },
  // 子任务详情
  {
    path: '/task-management/setup/inquiry/details/subtask-details',
    name: 'TaskSetupInquirySubtaskDetails',
    meta: {
      index: 5,
    },
    component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/inquiry/details/subtask-details.vue'),
  },
]
export default taskInquiryRouter;