<template>
	<!-- 提交提示 -->
	<van-overlay :show="show" @click="cancelClick">
		<div class="over-style">
			<div class="bottom-cancel" @click.stop>
				<div class="hint-style">{{hintText}}</div>
				<van-button class="button-cancel" color="#FF0000" plain size="large" @click="cancelClick">
					{{cancelText}}</van-button>
			</div>
			<van-button class="bottom-submit" color="#4F7AFD" plain size="large" @click="submitSure">{{submitText}}
			</van-button>
		</div>
	</van-overlay>
</template>

<script>
	export default {
		data() {
			return {
				overShow: false,
			}
		},
		props: {
			//提交提示文字
			hintText: {
				type: String,
				default: "提交之后不可再次编辑，确定提交吗？",
			},
			//取消文字
			cancelText: {
				type: String,
				default: "我再想想",
			},
			//确认文字
			submitText: {
				type: String,
				default: "确认提交",
			},
			show:{
				type: Boolean,
				default: false,
			}
		},
		methods: {
			// 提交
			submitSure(){
				this.$emit('submit')
			},
			//取消提交
			cancelClick(){
				this.$emit('cancel')
			}
		},
		mounted() {

		},
	}
</script>

<style scoped lang="scss">
	.over-style {
		display: flex;
		justify-content: center;

		.bottom-cancel {
			width: 355px;
			bottom: 83px;
			position: fixed;
			height: 99px;
			background: #FFFFFF;
			// opacity: 0.9;
			border-radius: 12px;
			// height: 57px;
		}

		.bottom-submit {
			width: 355px;
			height: 55px;
			background: #FFFFFF;
			border-radius: 12px;
			bottom: 20px;
			position: fixed;
			border: none;
			font-size: 18px;
			font-weight: 500;
			// color: #4F7AFD;
			// font-family: SourceHanSansSC-Medium;
		}

		.hint-style {
			line-height: 42px;
			height: 42px;
			font-size: 12px;
			// font-family: Source Han Sans SC;
			font-weight: 400;
			color: #777777;
			border-bottom: 1px solid #CDCDCD;
		}

		.button-cancel {
			border: none;
			height: 57px;
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;
			font-size: 18px;
			// font-family: Source Han Sans SC;
			font-weight: 400;
			// color: #FF0000;
		}
	}
</style>
