import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		extantDangerNum: 0,
		amendDangerNum: 0,
		isIosMoveBack: false,

		// 当前用户信息
		userRole:JSON.parse(localStorage.getItem('USER_ROLE')) || {},
	},
	mutations: {
		changeExtantDangerNum(state,num) {
			state.extantDangerNum = num
		},
		changeAmendDangerNumNum(state,num) {
			state.amendDangerNum = num
		},
		setIsIosMoveBack (state, param) {
			state.isIosMoveBack = param
		},

		setUserRole(state, data) {
      state.userRole = data;
      localStorage.setItem('USER_ROLE', JSON.stringify(data));
    },
	},
	actions: {},
	modules: {}
})
