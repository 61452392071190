//广告费用效益分析看板
let advCostBoardRouter = [
  //广告费用效益分析看板监控页
  {
    path: '/task-management/advcostboard/index',
    name: 'TaskAdvcost',
    component: () => import('../views/task-management/advcost-board/index.vue')
  },
  //数据对比
  {
    path: '/task-management/advcostboard/data-comparison/:marketCode/:marketName/:taskPeriod',
    name: 'TaskAdvcostDataComparison',
    component: () => import('../views/task-management/advcost-board/data-comparison.vue')
  },
  //任务中心
  {
    path: '/task-management/advcostboard/mission-center',
    name: 'TaskAdvcostMissionCenter',
    component: () => import('../views/task-management/advcost-board/mission-center.vue')
  },
  //任务详情
  {
    path: '/task-management/advcostboard/mission-details/:id',
    name: 'TaskAdvcostMissionDetails',
    component: () => import('../views/task-management/advcost-board/mission-details.vue')
  },
  //模板库
  {
    path: '/task-management/advcostboard/template-library',
    name: 'TaskAdvcostTemplateLibrary',
    component: () => import('../views/task-management/advcost-board/templater-library.vue')
  },
  //模板详情
  {
    path: '/task-management/advcostboard/template-details/:id',
    name: 'TaskAdvcostTemplateDetails',
    component: () => import('../views/task-management/advcost-board/template-details.vue')
  },
  //任务调整
  {
    path: '/task-management/advcostboard/mission-adjustment/:marketCode/:marketName/:taskPeriod',
    name: 'TaskAdvcostMissionAdjustment',
    component: () => import('../views/task-management/advcost-board/mission-adjustment.vue')
  },
  //调整记录
  {
    path: '/task-management/advcostboard/mission-adjustment-record/:marketCode/:marketName/:taskPeriod',
    name: 'TaskAdvcostMissionAdjustmentRecord',
    component: () => import('../views/task-management/advcost-board/mission-adjustment-record.vue')
  },
  //调整记录详情
  {
    path: '/task-management/advcostboard/mission-adjustment-record-details/:id',
    name: 'TaskAdvcostMissionAdjustmentRecordDetails',
    component: () => import('../views/task-management/advcost-board/record-details.vue')
  },
]
export default advCostBoardRouter;