<template>
	<!-- 下拉刷新 -->
	<div>
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
			<!-- 下拉提示，通过 scale 实现一个缩放效果 -->
			<template #pulling="props">
				<div class="pullRefresh-text">下拉即可刷新...</div>
			</template>
			<!-- 释放提示 -->
			<template #loosing>
				<div  class="pullRefresh-text">释放即可刷新...</div>
			</template>
			<slot></slot>
		</van-pull-refresh>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isLoading:false
			}
		},
		props: {
			// isLoading: {
			// 	type: Boolean,
			// 	default: false
			// },
		},
		methods: {
			onRefresh(){
				this.$emit('refresh')
			},
			refreshSuccess(){
				this.isLoading = false
			}

		},
		mounted() {
			
		},
	}
</script>

<style scoped lang="scss">
	
</style>