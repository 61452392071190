//仓储管理
let storageRouter = [
    //仓储列表
    {
        path: '/storage/list',
        name: 'storageListName',
        component: () => import( /* webpackChunkName: "about" */ '../views/storage/index.vue')
    },
    //订单详情
    {
        path: '/storage/details',
        name: 'storageDetailsName',
        component: () => import( /* webpackChunkName: "about" */ '../views/storage/details')
    },
]
export default storageRouter;