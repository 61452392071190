// 消息通知
let messageRouter = [
    {
        path: '/message',
        name: 'messageName',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/index.vue')
    },
    {
        path: '/messageInfo',
        name: 'messageInfoName',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/messageInfo.vue')
    },
    {
        path: '/projectSubcontract',
        name: 'projectSubcontract',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/projectSubcontract.vue') // 自理项目分包单
    },
    {
        path: '/NewSubcontract',
        name: 'NewSubcontract',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/NewSubcontract.vue') // 新分包单---上传
    },
    {
        path: '/ResultSubcontract',
        name: 'ResultSubcontract',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/ResultSubcontract.vue') // 分包单验收结果
    },
    {
        path: '/ApplyFinancial',
        name: 'ApplyFinancial',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/ApplyFinancial.vue') // 申请财务放款
    },
    {
        path: '/ApplyFinancialNew',
        name: 'ApplyFinancialNew',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/ApplyFinancialNew.vue') // 申请财务放款 新
    },
    {
        path: '/AdvancePaymentCheck',
        name: 'AdvancePaymentCheck',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/AdvancePaymentCheck.vue') // 预订款缴费审核
    },
    {
        path: '/PlaceOrder',
        name: 'PlaceOrder',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/PlaceOrder.vue') // 下单
    },
    {
        path: '/ConfirmationSlip',
        name: 'ConfirmationSlip',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/ConfirmationSlip.vue') // 确认单确认退---自理销售
    },
    {
        path: '/ConfirmationSlip1',
        name: 'ConfirmationSlip1',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/ConfirmationSlip1.vue') // 确认单确认退---工程单详情
    },
    {
        path: '/Installation',
        name: 'Installation',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/Installation.vue') // 安装完毕
    },
    {
        path: '/CreateStoreDetails',
        name: 'CreateStoreDetails',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/CreateStoreDetails.vue') // 入库通知--自理销售
    },
    {
        path: '/CreateStoreDetails1',
        name: 'CreateStoreDetails1',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/CreateStoreDetails1.vue') // 入库通知--工程单
    },
    {
        path: '/AdvancePayment',
        name: 'AdvancePayment',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/AdvancePayment.vue') // 收银通知
    },
    {
        path: '/SupplierOrderDetail',
        name: 'SupplierOrderDetail',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/SupplierOrderDetail.vue') // 供应商订单详情（工程单）
    },
    {
        path: '/SupplierOrderDetail1',
        name: 'SupplierOrderDetail1',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/SupplierOrderDetail1.vue') // 供应商订单详情（自理销售）
    },
    {
        path: '/SupplierOrderDetail2',
        name: 'SupplierOrderDetail2',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/SupplierOrderDetail2.vue') //  供应商订单详情（工程单）深化审核
    },
    {
        path: '/DistributionSpecial',
        name: 'DistributionSpecial',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/DistributionSpecial.vue') // 分配产品专员
    },
    {
        path: '/SelectionListDetails',
        name: 'SelectionListDetails',
        component: () => import( /* webpackChunkName: "about" */ '../views/message/SelectionListDetails.vue') // 选材单详情
    },
]
export default messageRouter;