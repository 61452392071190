import axios from 'axios'
import {
    Toast
} from 'vant';
import md5 from 'js-md5'
import { sha256 } from 'js-sha256';
import {
    Base64
} from 'js-base64';
//是否报错弹窗
let isAlert  = true
axios.defaults.withCredentials = false;
// 添加一个请求拦截器
axios.interceptors.request.use((config) => {
    if (config.url.indexOf('/customer/consumerInfo/queryById')>-1){
        //如果是扫一扫进详情页报错则不弹出错误信息
        isAlert = false
    }
    if (config.url.indexOf('/backend/validate/img/public') == -1 && config.url.indexOf('/author/oauth/token') == -1) {
        const rd = Math.random().toString().slice(-6);
        const timestamp = new Date().getTime();
        const token = sessionStorage.getItem('TOKEN');
        config.headers.uid1 = token;
        config.headers.uid2 = rd;
        config.headers.uid3 = timestamp;
        config.headers.uid4 = sha256(token + rd + timestamp);
    }
    if (config.url.indexOf('/author/oauth/token') > -1) {
        // let obj = JSON.parse(config.data)
        const pwd = md5(config.data.get("password"));
        let formData = new FormData();
        formData.append('grant_type', 'captcha');
        formData.append('password', pwd);
        formData.append('username', config.data.get("username"));
        config.headers['key'] = config.data.get("key")
        config.headers['code'] = config.data.get("code")
        // config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
        config.headers['Authorization'] = `Basic ${Base64.encode(config.data.get("username") + ':' + pwd)}`
        config.data = formData
    }
    if (config.method === 'get' && config.params) {
        let url = config.url + '?';
        for (const propName of Object.keys(config.params)) {
            const value = config.params[propName];
            var part = encodeURIComponent(propName) + "=";
            if (value !== null && typeof (value) !== "undefined") {
                if (typeof value === 'object') {
                    for (const key of Object.keys(value)) {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        url += subPart + encodeURIComponent(value[key]) + "&";
                    }
                } else {
                    url += part + encodeURIComponent(value) + "&";
                }
            }
        }
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    return config
}, (error) => Promise.reject(error));
// 添加一个响应拦截器
axios.interceptors.response.use((res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    //后台报错检查An error occurred while attempting to decode the Jwt: Jwt expired at出现时改为中文提示 “JWT已过期，请重新登陆”
    if (res.data.msg?.indexOf('An error occurred while attempting to decode the Jwt: Jwt expired at') != -1){
        res.data.msg = "JWT已过期，请重新登陆"
    }
    // 获取错误信息
    if (code === 401) {
        Toast.fail(res.data.msg)
        return res
    } else if (code === 500) {
        if (!isAlert) return res
        Toast.fail(res.data.msg)
        return res
    } else if (code !== 200) {
        Toast.fail(res.data.msg)
        return res
    } else {
        return res
    }
}, (error) => {
    const res = error.response
    //后台报错检查An error occurred while attempting to decode the Jwt: Jwt expired at出现时改为中文提示 “JWT已过期，请重新登陆”
    if (res.data.msg?.indexOf('An error occurred while attempting to decode the Jwt: Jwt expired at') != -1) {
        res.data.msg = "JWT已过期，请重新登陆"
    }
    if (res.status === 400) {
        Toast.fail(res.data.msg || '网络异常!')
    } else if (res.status === 401) {
        Toast.fail(res.data.msg || '网络异常!')
    } else if (res.status === 500) {
        Toast.fail(res.data.msg || '网络异常!')
    } else if (res.status !== 200) {
        Toast.fail(res.data.msg || '网络异常!')
    }
    return Promise.reject(error);
});

// 通用方法
export const POST = (url, params) => axios.post(url, params).then(
    (res) => res.data
).catch(
    (err) => Promise.reject(err)
)

export const GET = (url, params) => axios.get(url, {
    params
}).then((res) => res.data)
    .catch( 
        (err) => Promise.reject(err)
    )

export const PUT = (url, params) => axios.put(url, params).then((res) => res.data).catch(
    (err) => err
);

export const DELETE = (url, params) => axios.delete(url, {
    params
}).then((res) => res.data);

export const QPUT = (url, params) => axios.put(url, {
    params
}).then((res) => res.data);

// 下载文件的GET请求函数（添加responseType: 'blob'选项）
export const downloadFileGET = (url, params) => axios.get(url, {
    params,
    responseType: 'blob', // 添加响应类型为blob的选项
  }).then((res) => res.data)
    .catch((err) => Promise.reject(err));