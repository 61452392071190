// 统计
let statisticsRouter = [
    //统计首页
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import( /* webpackChunkName: "about" */ '../views/statistics/index.vue')
    },
    // 统计
    {
        path: '/statistics/CompentStatistics',
        name: 'CompentStatisticsIndex',
        component: () => import( /* webpackChunkName: "about" */ '../views/statistics/CompentStatistics/index.vue')
    },
    // 人员流动
    {
        path: '/statistics/PersonLineChart',
        name: 'PersonLineChart',
        component: () => import( /* webpackChunkName: "about" */ '../views/statistics/CompentStatistics/PersonLineChart.vue')
    },
    // 订单数据
    {
        path: '/statistics/MarketSales',
        name: 'MarketSales',
        component: () => import( /* webpackChunkName: "about" */ '../views/statistics/CompentStatistics/MarketSales.vue')
    },
]
export default statisticsRouter;