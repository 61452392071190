//客户管理-签单
let clientRouter = [
    //客户签单列表
    {
        path: '/client/signBillList',
        name: 'clientSignBillListName',
        meta: { 
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '../views/client/signBill/index.vue')
    },
    //客户详情
    {
        path: '/client/clientDetails/:id',
        name: 'clientDetailsName',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/signBill/clientDetails.vue')
    },
    //客户列表-客服
    {
        path: '/client/TelephoneOperatorList',
        name: 'clientTelephoneOperatorName',
        meta: { 
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '../views/client/telephoneOperator/index.vue')
    },
    //客户详情
    {
        path: '/client/clientTDetails/:id/:type',
        name: 'clientTDetailsName',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/telephoneOperator/clientData.vue')
    },
    //客户详情-话务
    {
        path: '/client/telephone/details/:id',
        name: 'clientTelephoneDetailsName',
        component: () => import( /* webpackChunkName: "about" */ '../views/client/telephone/clientDetails.vue')
    },
]
export default clientRouter;