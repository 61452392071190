import Vue from 'vue'
import VueRouter from 'vue-router'
import engineeringRouter from './engineering' //工程
import deepenRouter from './deepen' //深化
import clientRouter from './client' //客户
import storageRouter from './storage' //仓储
import statisticsRouter from './statistics' //统计
import messageRouter from './message' //消息通知
import dataBoardRouter from './dataBoard'
import presidentRouter from './president'
import taskManagementRouter from './task-management'  // 3.0看板
import advCostBoardRouter from './advcost-board'  // 3.0看板 广告费用效益分析看板

import TdirectorRouter from './Tdirector'
import DirectorOperationsRouter from './directorOperations'

const isTest = process.env.VUE_APP_MODE === 'test';
Vue.use(VueRouter)

const routes = [{
    //首页
    path: '/',
    name: 'indexName',
    component: () => import( /* webpackChunkName: "about" */ '../views/homePage/index.vue'),
},
{
    //首页
    path: '/login',
    name: 'loginName',
    component: () => import( /* webpackChunkName: "about" */ '../views/login.vue'),
},
// {
//     //签单模块
//     path: '/signBill',
//     name: 'indexPageName',
//     component: () => import( /* webpackChunkName: "about" */ '../views/signBill/index.vue'),
//     redirect: '/signBill/myClient',
//     children: [
//         //我的客户首页
//         {
//             path: 'myClient',
//             name: 'myClientName',
//             component: () => import( /* webpackChunkName: "about" */ '../views/signBill/myClient/index.vue')
//         },
//         //客户信息
//         {
//             path: 'myClient/cllintDetails',
//             name: 'cllintDetailsChildrenName',
//             component: () => import( /* webpackChunkName: "about" */
//                 '../views/signBill/myClient/clientDetails.vue'),
//             children: []
//         },
//         //跟访记录
//         {
//             path: 'myClient/followList',
//             name: 'followListChildrenName',
//             component: () => import( /* webpackChunkName: "about" */
//                 '../views/signBill/myClient/followList.vue'),
//             children: []
//         },
//         //新增跟访记录
//         {
//             path: 'myClient/addFollow',
//             name: 'addFollowChildrenName',
//             component: () => import( /* webpackChunkName: "about" */
//                 '../views/signBill/myClient/addFollow.vue'),
//             children: []
//         },
//         //新增跟访历史
//         {
//             path: 'myClient/addFollowHistory',
//             name: 'addFollowHistoryChildrenName',
//             component: () => import( /* webpackChunkName: "about" */
//                 '../views/signBill/myClient/addFollowHistory.vue'),
//             children: []
//         },
//         //跟访历史
//         {
//             path: 'myClient/followHistory',
//             name: 'followHistoryChildrenName',
//             component: () => import( /* webpackChunkName: "about" */
//                 '../views/signBill/myClient/followHistory.vue'),
//             children: []
//         },
//         //客户基本信息
//         {
//             path: 'myClient/clientInfo',
//             name: 'clientInfoChildrenName',
//             component: () => import( /* webpackChunkName: "about" */
//                 '../views/signBill/myClient/clientInfo.vue'),
//             children: []
//         },
//         //编辑客户基本信息
//         {
//             path: 'myClient/editClientInfo',
//             name: 'editClientInfoChildrenName',
//             component: () => import( /* webpackChunkName: "about" */
//                 '../views/signBill/myClient/editClientInfo.vue'),
//             children: []
//         },
//         //擂台榜
//         {
//             path: 'listRing',
//             name: 'listRingName',
//             component: () => import( /* webpackChunkName: "about" */ '../views/signBill/listRing/index.vue')
//         },
//         //个人统计
//         {
//             path: 'statistics',
//             name: 'statisticsName',
//             component: () => import( /* webpackChunkName: "about" */
//                 '../views/signBill/statistics/index.vue')
//         }
//     ]
// },
// //话务模块
// {
//     path: '/telephone',
//     name: 'telephoneName',
//     component: () => import( /* webpackChunkName: "about" */ '../views/telephone/index.vue'),
//     redirect: '/telephone/myClient',
//     children: [{
//         path: 'myClient',
//         name: 'myClient',
//         component: () => import( /* webpackChunkName: "about" */
//             '../views/telephone/myClient/index.vue')
//     },
//     {
//         path: '/myClient/clientDetails',
//         name: 'clientDetailsChildren',
//         component: () => import( /* webpackChunkName: "about" */
//             '../views/telephone/myClient/clientDetails.vue'),
//         children: []
//     },
//     {
//         path: '/myClient/followList',
//         name: 'followListChildren',
//         component: () => import( /* webpackChunkName: "about" */
//             '../views/telephone/myClient/followList.vue'),
//         children: []
//     },
//     {
//         path: '/myClient/addFollow',
//         name: 'addFollowChildren',
//         component: () => import( /* webpackChunkName: "about" */
//             '../views/telephone/myClient/addFollow.vue'),
//         children: []
//     },
//     {
//         path: '/myClient/addFollowHistory',
//         name: 'addFollowHistoryChildren',
//         component: () => import( /* webpackChunkName: "about" */
//             '../views/telephone/myClient/addFollowHistory.vue'),
//         children: []
//     },
//     {
//         path: '/myClient/followHistory',
//         name: 'followHistoryChildren',
//         component: () => import( /* webpackChunkName: "about" */
//             '../views/telephone/myClient/followHistory.vue'),
//         children: []
//     },
//     {
//         path: '/myClient/followDetail',
//         name: 'followDetailChildren',
//         component: () => import( /* webpackChunkName: "about" */
//             '../views/telephone/myClient/followDetail.vue'),
//         children: []
//     },
//     {
//         path: '/myClient/clientInfo',
//         name: 'clientInfoChildren',
//         component: () => import( /* webpackChunkName: "about" */
//             '../views/telephone/myClient/clientInfo.vue'),
//         children: []
//     },
//     {
//         path: '/myClient/editClientInfo',
//         name: 'editClientInfoChildren',
//         component: () => import( /* webpackChunkName: "about" */
//             '../views/telephone/myClient/editClientInfo.vue'),
//         children: []
//     },
//     {
//         path: 'statistics',
//         name: 'statistics',
//         component: () => import( /* webpackChunkName: "about" */
//             '../views/telephone/statistics/index.vue')
//     }
//     ]
// },
    ...engineeringRouter,
    ...deepenRouter,
    ...clientRouter,
    ...storageRouter,
    ...statisticsRouter,
    ...messageRouter,
    ...dataBoardRouter,
    ...presidentRouter,
    ...taskManagementRouter,
    ...TdirectorRouter,
    ...DirectorOperationsRouter,
    ...advCostBoardRouter
]

const router = new VueRouter({
    mode: isTest ? "hash" : "history", // history去掉url中的#
    scrollBehavior: () => ({
        y: 0
    }),
    routes
})

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router
