
let DirectorOperationsRouter = [
    //运营总监看板
    {
        path: '/directorOperations',
        name: 'directorOperationsName',
        component: () => import( /* webpackChunkName: "about" */ '../views/directorOperations/index.vue'),
        meta: { keepAlive: true }
    },
    //签单详情
    {
        path: '/director/signDetails',
        name: 'TdirectorSignDetailsName',
        component: () => import( /* webpackChunkName: "about" */ '../views/directorOperations/signDetails.vue')
    },
    //设计师资源详情
    {
        path: '/director/designDetails',
        name: 'TdirectorDesignDetailsName',
        component: () => import( /* webpackChunkName: "about" */ '../views/directorOperations/designDetails.vue')
    },
    //设计师产值详情
    {
        path: '/director/designproductDetails',
        name: 'TdirectorDesignProductDetailsName',
        component: () => import( /* webpackChunkName: "about" */ '../views/directorOperations/designProductDetails.vue')
    },
]
export default DirectorOperationsRouter;