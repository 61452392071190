let router = [
    //模块
    {
        path: '/task-management/board/human-resources/market-list',
        name: 'TaskBoardHumanResourcesMarketList',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/human-resources/market-list.vue'),
        meta: {
            index: 2,
        },
    },
    {
        path: '/task-management/board/human-resources/employee-list',
        name: 'TaskBoardHumanResourcesEmployeeList',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/human-resources/employee-list.vue'),
        meta: {
            index: 3,
        },
    },
    {
        path: '/task-management/board/human-resources/recruitment-trial-detail',
        name: 'TaskBoardHumanResourcesRecruitmentTrialDetail',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/human-resources/recruitment-trial-detail.vue'),
        meta: {
            index: 4,
        },
    },
    {
        path: '/task-management/board/human-resources/employee-single',
        name: 'TaskBoardHumanResourcesEmployeeSingle',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/human-resources/employee-single.vue'),
        meta: {
            index: 5,
        },
    },
    {
        path: '/task-management/board/human-resources/upload-task',
        name: 'TaskBoardHumanResourcesUploadTask',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/human-resources/upload-task.vue'),
        meta: {
            index: 6,
        },
    },
    {
        path: '/task-management/board/human-resources/examine-detail',
        name: 'TaskBoardHumanResourcesExamineDetail',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/board/human-resources/examine-detail.vue'),
        meta: {
            index: 6,
        },
    },

    // -------------------------任务设置-------------------------------
    {
        path: '/task-management/setup/human-resources/index',
        name: 'TaskSetupHumanResources',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/human-resources/index.vue'),
        meta: {
            index: 2,
        },
    },
    {
        path: '/task-management/setup/human-resources/examine-list',
        name: 'TaskSetupHumanResourcesExamineList',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/human-resources/examine-list.vue'),
        meta: {
            index: 3,
        },
    },
    {
        path: '/task-management/setup/human-resources/create-task',
        name: 'TaskSetupHumanResourcesCreateTask',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/human-resources/create-task.vue'),
        meta: {
            index: 4,
        },
    },
    {
        path: '/task-management/setup/human-resources/task-details',
        name: 'TaskSetupHumanResourcesTaskDetails',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/human-resources/task-details.vue'),
        meta: {
            index: 4,
        },
    },
    {
        path: '/task-management/setup/human-resources/sub-task',
        name: 'TaskSetupHumanResourcesSubTask',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/human-resources/sub-task.vue'),
        meta: {
            index: 5,
        },
    },
    {
        path: '/task-management/setup/human-resources/create-sub-task',
        name: 'TaskSetupHumanResourcesCreateSubTask',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/human-resources/create-sub-task.vue'),
        meta: {
            index: 6,
        },
    },
    {
        path: '/task-management/setup/human-resources/sub-task-details',
        name: 'TaskSetupHumanResourcesSubTaskDetails',
        component: () => import( /* webpackChunkName: "about" */ '../../views/task-management/setup/human-resources/sub-task-details.vue'),
        meta: {
            index: 6,
        },
    },
]
export default router;